import React, { Component } from "react"
import { Section, QuickInfoWrapper } from "../../utils"

export default class GreedyMimicInfo extends Component {
  render() {
    return (
      <Section style={{ width: "100%" }}>
        <QuickInfoWrapper>
          <p className="text">
            <u>Description:</u>
            <br />
            Simple platformer about a Mimic that likes to lick people and turn
            them into ogres.
            <br />
            <br />
            Created by eddynardo.
            <br />
            Graphics by 0×72 and Adam Saltsman.
            <br />
            Song “The Black Frame” by Rolemusic under a CC BY 4.0 attribution
            licence.
            <br />
            <br />
          </p>
          <p className="controllerText">
            <u>Controls:</u>
            <br />
            Keyboard
            <br />
            Move: WASD or Arrow keys
            <br />
            Jump: W or Up Arrow key
            <br />
            Stomp: S or Down Arrow key
            <br />
            Eat: X or J<br />
            Restart: R<br />
            Mute Sound: M<br />
            Menu: Escape
            <br /> <br />
            XBOX controller
            <br />
            Move: D-pad
            <br />
            Jump: Button A<br />
            Stomp: Button B<br />
            Eat: Button X<br />
            Restart: Button Start
            <br />
            Mute: Button Y<br />
            Menu:Button Back
            <br />
          </p>
        </QuickInfoWrapper>
      </Section>
    )
  }
}
